.disabledLink:hover {
  cursor: default !important;
  text-decoration: none !important;
}

.disabledLink a,
.disabledLink a:hover {
  color: white !important;
  text-decoration: none !important;
  cursor: default !important;
}
