.container {
  width: 65rem;
  max-width: 90rem;
  margin-top: 40px;
}

.responsiveContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 20px 5% 40px 5%;
}

.responsiveContainer.default {
  max-width: 740px;
}

.responsiveContainer.max {
  max-width: 1040px;
}
